import * as React from 'react';

import { useRouter } from 'next/router';

import { Button } from '@components/ui/Button';

import useTranslation from '@hooks/useTranslation';

import styles from './CertificationBanner.module.css';

const Content = () => {
    const { t } = useTranslation('pages/resources/_blocks/certification');
    const { locale } = useRouter();

    let certificationUrl = 'https://certification.getresponse.com';
    if (locale === 'pl') {
        certificationUrl = 'https://certyfikacja.getresponse.pl';
    }

    return (
        <>
            <div className={styles.inner}>
                <span>{t('resourcesCertificationHeader')}</span>
                <div className={styles.btn_wrap}>
                    <a href={certificationUrl}>
                        <Button
                            label={t('resourcesCertificationBtn')}
                            className={styles.btn}
                            color="green"
                            size="small"
                        ></Button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default function CertificationBanner({ container }) {
    return (
        <section className={styles.certification}>
            {React.cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}
