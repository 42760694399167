import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import Container from '@components/global/Container';
// import BannerUkraine from '@components/layouts/BannerUkraine';
//
import Seo from '@components/global/Seo';
import CertificationBanner from '@components/pages/resources/CertificationBanner';
import JsonLd from '@components/pages/resources/JsonLd';
import MarketingLinks from '@components/pages/resources/MarketingLinks';
import NewsletterForm from '@components/pages/resources/NewsletterForm';
import ResourceList from '@components/pages/resources/ResourceList';
import TopBar from '@components/pages/resources/TopBar';

import { getCanonical } from '@helpers/alternates';
import getGlobalProps from '@helpers/getGlobalProps';
import { getPosts } from '@helpers/resources/helpers';

import latestBlogPosts from '@data/LatestBlogPosts/data.preval';
import useTranslation from '@hooks/useTranslation';

export default function Resources({ query, searchQuery, sort, roles, types, topics, resources, totalResults, page }) {
    const { t } = useTranslation('pages/resources/index');
    const { pathname, locale } = useRouter();
    const { geo } = useAppContext();
    const canonical = getCanonical(pathname) + (![null, 0, 1].includes(page) ? `?p=${page}` : '');
    const supportedParams = ['query', 'sort', 'type', 'category', 'company-or-business-role'];
    const [hideAside, setHideAside] = useState(false);
    useEffect(() => {
        // TODO: Example hide Aside (filter)
        // setHideAside(locale === 'de' ? true : false);
        return () => {
            setHideAside(false);
        };
    }, [locale]);

    const hasParams = Object.keys(query).filter((param) => supportedParams.includes(param)).length > 0;
    const prepareLatestBlogPosts = latestBlogPosts[locale] ? latestBlogPosts[locale] : latestBlogPosts['en'];
    return (
        <>
            <Seo
                title={t('seo.title')}
                description={t('seo.description')}
                noindex={hasParams || resources.length === 0 ? true : false}
                nofollow={hasParams || resources.length === 0 ? true : false}
                canonical={hasParams || resources.length === 0 ? null : canonical}
                languageAlternates={page || hasParams ? [] : undefined}
            />
            <JsonLd resources={resources} />
            <main>
                <TopBar container={<Container />} />
                <ResourceList
                    container={<Container />}
                    query={query}
                    searchQuery={searchQuery}
                    sort={sort}
                    roles={roles}
                    types={types}
                    topics={topics}
                    resources={resources}
                    totalResults={totalResults}
                    page={page}
                    hideAside={hideAside}
                />
                {locale !== 'de' && locale !== 'it' && locale !== 'ru' && <CertificationBanner container={<Container />} />}
                {locale !== 'de' && locale !== 'ru' && (
                    <MarketingLinks
                        container={<Container />}
                        blogData={prepareLatestBlogPosts}
                    />
                )}
                {locale !== 'it' && locale !== 'ru' && geo?.country_iso_code !== 'RU' && <NewsletterForm container={<Container />} />}
            </main>
        </>
    );
}

export async function getServerSideProps({ query, locale, req, res, ...context }) {
    const globalProps = await getGlobalProps({ ...context, locale }, '/resources');
    const allowedQueryParams = ['query', 'p', 'sort', 'category', 'type', 'company-or-business-role'].filter((param) => Boolean(query[param]));

    const filteredQuery = Object.fromEntries(allowedQueryParams.map((param) => [param, query[param]]));
    // const NextRequestMetaSymbol = Reflect.ownKeys(req).find((key) => key.toString() === 'Symbol(NextInternalRequestMeta)');
    // const isFullReload = !req[NextRequestMetaSymbol].initURL.includes('_next/data');
    const NextRequestMetaSymbol = Reflect.ownKeys(req).find((key) => key.toString() === 'Symbol(NextRequestMeta)');
    const isFullReload = !req[NextRequestMetaSymbol]['__NEXT_INIT_URL'].includes('_next/data');

    const { totalPosts, posts, aggregations } = await getPosts(
        filteredQuery.p > 1 ? filteredQuery.p : 1,
        locale,
        filteredQuery.query ?? null,
        !!filteredQuery.query ? null : filteredQuery.sort === 'name_asc' ? 'name_asc' : 'date_desc',
        filteredQuery.category,
        filteredQuery.type,
        filteredQuery['company-or-business-role'],
    );

    if (posts.length === 0 && isFullReload) {
        res.statusCode = 404;
    }

    if (filteredQuery.p === '1') {
        const [baseUrl, searchParamsString] = req.url.split('?');
        const searchParams = new URLSearchParams(searchParamsString);
        searchParams.delete('p');
        const searchParamsAsString = searchParams.toString();
        const redirectUrl = baseUrl + (searchParamsAsString !== '' ? `?${searchParamsAsString}` : '');
        return {
            redirect: {
                destination: (req[NextRequestMetaSymbol]['__nextStrippedLocale'] ? `/${locale}` : '/') + redirectUrl,
                permanent: true,
            },
        };
    }

    return {
        // notFound: data.results.length === 0 && !query.query && isFullReload,
        props: {
            ...globalProps,
            ...{
                query: filteredQuery,
                searchQuery: filteredQuery.query || null,
                sort: filteredQuery.sort || null,
                roles:
                    aggregations?.company_or_business_role
                        ?.map((item) => ({
                            ...item,
                            selected: filteredQuery['company-or-business-role'] === item.key || !!(typeof filteredQuery['company-or-business-role'] === 'object' && filteredQuery['company-or-business-role']?.includes(item.key)),
                        }))
                        .sort((a, b) => a.key.localeCompare(b.key)) ?? [],
                topics:
                    aggregations.category
                        ?.map((item) => ({ ...item, selected: filteredQuery['category'] === item.key || !!(typeof filteredQuery['category'] === 'object' && filteredQuery['category']?.includes(item.key)) }))
                        .sort((a, b) => a.key.localeCompare(b.key)) ?? [],
                types:
                    aggregations?.type
                        ?.map((item) => ({ ...item, selected: filteredQuery['type'] === item.key || !!(typeof filteredQuery['type'] === 'object' && filteredQuery['type']?.includes(item.key)) }))
                        .sort((a, b) => a.key.localeCompare(b.key)) ?? [],
                resources: posts,
                totalResults: totalPosts,
                page: filteredQuery.p > 1 ? filteredQuery.p * 1 : null,
            },
        },
    };
}
